// import ResizeObserver from 'resize-observer-polyfill';
// import createObserver from '../utils/observer';

// const { observe, unobserve } = createObserver(ResizeObserver);

import resizeObserver from '../utils/resizeObserver'

export default img => {
	const update = width => img.setAttribute('sizes', width + 'px');
	update(img.getBoundingClientRect().width);
	img.setAttribute('srcset', img.dataset.srcset);
	const onSizeChange = entry => update(entry.contentRect.width)
	resizeObserver.observe(img, onSizeChange);
	return () => resizeObserver.unobserve(img, onSizeChange)
}