import { createGroup } from './group';

const diff = (prev, next) => {
    const created = new Set();
    const updated = new Set();
    const removed = new Set(prev);
    for (const x of next) {
        if (prev.has(x)) {
            removed.delete(x);
            updated.add(x);
        } else {
            created.add(x);
        }
    }
    return { created, updated, removed };
}

const updateList = ({
    key = x => x,
    create,
    update,
    remove
}) => {
    const list = new Map();
    return items => {
        const keyedItems = new Map();
        for (const item of items) keyedItems.set(key(item), item);
        const prevKeys = new Set(list.keys());
        const nextKeys = new Set(keyedItems.keys());
        const {
            created: createdKeys,
            removed: removedKeys,
            updated: updatedKeys
        } = diff(prevKeys, nextKeys);
        for (const key of removedKeys) {
            const listItem = list.get(key);
            list.delete(key);
            if (remove) remove(listItem);
        }
        for (const key of createdKeys) {
            const item = keyedItems.get(key)
            const listItem = create(item);
            list.set(key, listItem);
        }
        if (update) {
            for (const key of updatedKeys) {
                const item = keyedItems.get(key);
                const listItem = list.get(key);
                update(listItem, item);
            }
        }
        return list;
    }
}

const defaultUpdate = (element, data) => {
    const event = new CustomEvent('update', { bubbles: false, detail: data });
    element.dispatchEvent(event)
}

export const renderChildren = ({
    container,
    bind,
    key,
    template,
    update = defaultUpdate,
}) => {
    const group = createGroup();
    return updateList({
        key,
        create: data => {
            const element = template(data);
            container.appendChild(element);
            group.bindElement(element, bind);
            if (update) update(element, data);
            return element;
        },
        update,
        remove: element => {
            group.unbindElement(element);
            container.removeChild(element)
        }
    })
}