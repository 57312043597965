import { createContext } from './context';
import { compose } from './utils';

const selectorContext = createContext()

export const bind = element => selectorContext.consume(element)(element);

export const bindChildren = element => {
    const bind = selectorContext.consume(element);
    return compose(...[...element.children].map(bind));
}

export const select = selectors => {
    const bindSelectors = container => {
        selectorContext.provide(container, bindSelectors)
        const cleanupFunctions = [];
        const traverse = element => {
            const matches = Object.keys(selectors).filter(s => element.matches(s));
            if (matches.length > 1) {
                throw new Error(`select: Element matched multiple selectors: ${matches.join(', ')}`);
            } else if (matches.length === 1) {
                const selector = matches[0];
                const bind = selectors[selector];
                cleanupFunctions.push(bind(element))
            } else {
                for (const child of element.children) traverse(child);
            }
        }
        traverse(container);
        return compose(...cleanupFunctions);
    }
    return bindSelectors;
}