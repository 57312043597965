import { bindChildren } from './lib';

export default nav => {
    const menu = nav.querySelector('.menu');
    const header = nav.querySelector('header');
    let isOpen = false;
    let isYellow = header.classList.contains("bg-yellow");
    const show = () => {
        menu.classList.remove('-translate-y-full');
        if (!isYellow) header.classList.add('bg-yellow');
        isOpen = true;
    }
    const hide = () => {
        menu.classList.add('-translate-y-full');
        if (!isYellow) header.classList.remove('bg-yellow');
        isOpen = false;
    }
    const toggle = () => isOpen ? hide() : show()
    nav.querySelector('button').addEventListener('click', toggle);
    return bindChildren(nav)
}