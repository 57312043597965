import { select } from './components/lib';
import image from './components/image'
import nav from './components/nav'
import bindIfVisible from './components/bindIfVisible'
import beer from './components/beer'

select({
    'img[data-srcset]': image,
    'nav': nav,
    '.bind-if-visible': bindIfVisible,
    '.beer': beer
})(document.body)