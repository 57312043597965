import { createContext } from './context';

export const createRegistry = key => {
    const context = createContext(key);
    return {
        provide(element) {
            const registry = new Map();
            context.provide(element, {
                register(element, value) {
                    registry.set(element, value);
                },
                unregister(element) {
                    registry.delete(element);
                }
            });
            return registry;
        },
        register(element, data) {
            const registry = context.consume(element);
            if (registry === null) throw new Error(`registry.register: No registry of type ${key} is in context`);
            registry.register(element, data);
            return () => registry.unregister(element, data);
        }
    }
}