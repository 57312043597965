import { renderChildren, bind, addEventListener, compose } from './lib';

export default container => {
    const children = [...container.children];
    children.forEach(child => container.removeChild(child));
    const render = renderChildren({
        container,
        key: x => x,
        template: x => x,
        bind
    })
    const update = () => {
        const { width, height } = container.getBoundingClientRect();
        if (width > 0 && height > 0) {
            render(children)
        } else {
            render([])
        }
    }
    update();
    return compose(
        addEventListener(window, 'resize', update),
        () => render([])
    )
}