export const compose = (...fs) => () => fs.forEach(f => f && f());

export const extend = (...components) => element =>
    compose(...components.map(bind => bind(element)))

export const dispatch = (element, event, detail) =>
    element.dispatchEvent(new CustomEvent(event, { detail, bubbles: true }))

export const addEventListener = (element, event, listener) => {
    element.addEventListener(event, listener);
    return () => element.removeEventListener(event, listener);
}

export const rAF = update => {
    let frame = null;
    const tick = () => {
        frame = requestAnimationFrame(tick);
        update();
    }
    tick();
    return () => cancelAnimationFrame(frame);
}

export const MapSet = () => {
    const map = new Map();
    return {
        has(key) { return map.has(key) }
    }
}