import { rAF, addEventListener, compose } from './lib';
import { map } from '../utils/math';
import {
    Box3,
    Vector3,
    Scene,
    OrthographicCamera,
    WebGLRenderer,
    PointLight,
    AmbientLight,
    Object3D,
    MeshPhongMaterial,
    MeshStandardMaterial,
    TextureLoader,
    DoubleSide,
    sRGBEncoding
} from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import GLTFUrl from '../assets/Beverage_Can.gltf';
import textureURL from '../assets/bier.jpg';
import 'intersection-observer-polyfill';
import intersectionObserver from '../utils/intersectionObserver';

const gltfLoader = new GLTFLoader();
const textureLoader = new TextureLoader();
const chrome = new MeshPhongMaterial({ side: DoubleSide })
const texture = textureLoader.load(textureURL);
texture.flipY = false;
texture.encoding = sRGBEncoding;
const label = new MeshStandardMaterial({ emissiveMap: texture });
label.roughness = 1;
label.metalness = 1;
label.emissive.set(0xffffff)

let canObject = null;
const loadGLTF = () => {
    if (canObject !== null) {
        return Promise.resolve(canObject);
    }
    return new Promise(resolve => gltfLoader.load(GLTFUrl, res => {
        const obj = res.scene.children[0];
        obj.rotation.y = 0;
        obj.scale.y = 0.9;
        obj.children[0].material = label;
        obj.children[1].material = chrome;
        const bounds = new Box3().setFromObject(obj);
        const container = new Object3D();
        obj.position.sub(bounds.getCenter(new Vector3()));
        container.add(obj);
        canObject = container;
        resolve(canObject);
    }));
}

const getBox3Vertices = box3 => [
    new Vector3(box3.min.x, box3.min.y, box3.min.z), // 000
    new Vector3(box3.min.x, box3.min.y, box3.max.z), // 001
    new Vector3(box3.min.x, box3.max.y, box3.min.z), // 010
    new Vector3(box3.min.x, box3.max.y, box3.max.z), // 011
    new Vector3(box3.max.x, box3.min.y, box3.min.z), // 100
    new Vector3(box3.max.x, box3.min.y, box3.max.z), // 101
    new Vector3(box3.max.x, box3.max.y, box3.min.z), // 110
    new Vector3(box3.max.x, box3.max.y, box3.max.z)  // 111
];

export default canvas => {

    const renderer = new WebGLRenderer({ canvas, alpha: true, antialias: true });
    const scene = new Scene();
    const camera = new OrthographicCamera();
    camera.position.z = 2000;
    camera.position.y = 600;
    camera.lookAt(new Vector3());
    const light = new PointLight(0xaaaaaa);
    scene.add(light, new AmbientLight(0x333333))
    light.position.set(camera.position.x, camera.position.y + 2000, camera.position.z);

    let loaded = false;
    const container = new Object3D();
    const cans = [new Object3D(), new Object3D(), new Object3D(), new Object3D(), new Object3D()]
    container.add(...cans);
    scene.add(container);
    const placementRadius = 0.05;
    cans.forEach((can, i) => {
        const angle = Math.PI / 2 + map(i, 0, cans.length, 0, Math.PI * 2);
        can.position.set(Math.cos(angle) * placementRadius, 0, Math.sin(angle) * placementRadius);
        can.rotation.y += angle + Math.PI * 1.1;
    })

    let visible = false;
    intersectionObserver.observe(canvas, ({ isIntersecting }) => {
        visible = isIntersecting;
    })

    const bounds = new Box3();
    const fitCans = () => {
        const prevRotations = cans.map(can => can.rotation.y);
        cans.forEach(can => can.rotation.y = 0);
        bounds.setFromObject(container);
        cans.forEach((can, i) => can.rotation.y = prevRotations[i]);
        const screenVertices = getBox3Vertices(bounds).map(v => v.project(camera))
        const screenBounds = new Box3().setFromPoints(screenVertices);
        const screenSize = screenBounds.getSize(new Vector3());
        const scale = Math.min(2 / screenSize.x, 2 / screenSize.y);
        container.scale.multiplyScalar(scale);
    }

    const onResize = () => {
        const rect = canvas.getBoundingClientRect();
        const width = rect.width * window.devicePixelRatio;
        const height = rect.height * window.devicePixelRatio;
        camera.left = width / -2;
        camera.right = width / 2;
        camera.top = height / 2;
        camera.bottom = height / -2;
        camera.far = 5000;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height, false);
        if (loaded) fitCans();
    }

    loadGLTF(GLTFUrl).then(canObject => {
        cans.forEach(c => c.add(canObject.clone(true)));
        fitCans();
        loaded = true;
    })

    let speed = 0;
    let prevScroll = window.pageYOffset;

    const update = () => {
        cans.forEach(can => can.rotation.y += speed);
        if (visible) renderer.render(scene, camera);
        speed *= .95;
    }

    const onScroll = () => {
        const delta = window.pageYOffset - prevScroll;
        speed += delta * -.00035;
        prevScroll = window.pageYOffset;
    }

    onResize();

    return compose(
        rAF(update),
        addEventListener(window, 'resize', onResize),
        addEventListener(window, 'scroll', onScroll),
        () => intersectionObserver.unobserve(canvas)
    )
}