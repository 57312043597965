export const createGroup = () => {
    const components = new Map();
    const group = {
        bindElement(element, bind) {
            if (components.has(element)) throw new Error("Group.bindElement: Element already bound", element);
            components.set(element, bind(element));
        },
        unbindElement(element) {
            if (!components.has(element)) throw new Error("Group.unbindElement: Element not bound", element);
            const cleanup = components.get(element);
            if (typeof cleanup === 'function') cleanup();
            components.delete(element);
        },
        unbindAll() {
            for (const element of components.keys())
                group.unbindElement(element)
        }
    }
    return group;
}