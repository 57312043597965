import { dispatch } from './utils';

let contextID = 0;
export const createContext = (key = String(contextID++)) => {
    const eventName = 'getContext:' + key;
    return {
        consume(element) {
            let value = null;
            dispatch(element, eventName, v => value = v);
            return value;
        },
        provide(element, value) {
            element.addEventListener(eventName, event => {
                if (event.target !== element) {
                    event.stopPropagation();
                    const setter = event.detail;
                    setter(value);
                }
            })
        }
    }
}